import { Model } from '@app/interfaces';
import { Column } from './column.class';
import { CellDisplay } from '../enums/cell-display.enum';
import { ButtonTypes, ButtonSizes } from '@app/components/platform/button/button.component';
import { TranslatableKey } from '@app/types/translatable.type';

/**
 * ButtonColumn displays a ui-button
 */
export abstract class ButtonColumn<T extends Model> extends Column {
    type = CellDisplay.button;
    buttonType: ButtonTypes = 'ghost';
    buttonSize: ButtonSizes = null;

    /**
     * Whether the button should be disabled.
     * This can be customized based on the state of the model.
     */
    disabled(_: T): boolean {
        return false;
    }

    /**
     * An optional help message to explain why the button is disabled.
     */
    disabledText(_: T): TranslatableKey | null {
        return null;
    }

    /**
     * buttonText is the text you want to show up on the button.
     * It will be translated if it's translatable.
     */
    abstract buttonText: string;

    /**
     * event is the string that will be passed to your view (along with the model)
     * ex: approve, delete, ect.
     */
    abstract event: string;

    /**
     * showIf is called to determine if we want to show this button.
     * ex: Approve button only shows up it not already approved.
     */
    abstract showIf(model: T): boolean;
}
